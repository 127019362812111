'use client';

import RightArrow from '@/assets/arrow24';

import { useFullScreenLoader } from '@/hooks/useFullScreenLoader';
import { useNotification } from '@/hooks/useNotification';
import { ApiState } from '@/models/generics';
import { locationService } from '@/services/location';
import { ICheckPincodeServiceabilityApiResponseData } from '@/services/location/models/check-pincode-serviceability';
import { dataLayerPushWithEvent } from '@/utils/callcustomevent';
import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { NotificationSeverity } from '../NotifyToast';
import { useLocation } from '@/hooks/useLocation';
import { TLocation } from '@/store/location/reducer';
import { useRouter } from 'next/router';
import { LOCATION_SELECTOR_TRIGGER_OPTIONS } from '@/store/location/actionTypes';
import FlexBox from '@/atomic-components/flex-box';
import UpArrow from '@/assets/city/arrow.svg'
import { emitMoeEvent } from '@/utils/events';

function setCookie() {
	if (typeof window !== 'undefined') {
		var date = new Date();
		date.setTime(date.getTime() + 400 * 24 * 60 * 60 * 1000);
		let expires = '; expires=' + date.toUTCString();
		document.cookie = 'citySelected' + '=' + 'true' + expires + '; path=/';
	}
}

type PincodeInputProps = {};

const PincodeInput = (props: PincodeInputProps) => {
	const [pincode, setPincode] = useState<string>('');
	const [pincodeError, setPincodeError] = useState<string | undefined>();
	const [checkPincodeViewState, setCheckPincodeViewState] = useState<ApiState>(
		ApiState.IDLE
	);
	const [showPincode,setShowPincode] = useState<boolean>(false);
	const { loader } = useFullScreenLoader();
	const { notifyActions } = useNotification();
	const { selectedLocation, locationActions, isLocationSelectorOpen } =
		useLocation();

	const router = useRouter();

	function onPincodeEnter(e: React.ChangeEvent<HTMLInputElement>) {
		setPincodeError(undefined);
		setPincode(e.currentTarget.value);
	}

	function checkIfPincodeValid(pincode: string) {
		const regex = /^[1-9][0-9]{5}$/;

		return regex.exec(pincode);
	}

	async function getEnteredPincodeDetails(): Promise<ICheckPincodeServiceabilityApiResponseData | null> {
		return locationService
			.getPincodeServiceabilityService(Number(pincode))
			.then((res) => {
				setCheckPincodeViewState(ApiState.COMPLETED);
				return res.data?.data;
			})
			.catch((error) => {
				setCheckPincodeViewState(ApiState.FAILED);
				return null;
			});
	}

	function saveLocationAndGetMeta(location: TLocation) {
		notifyActions.notifyUser({
			severity: NotificationSeverity.SUCCESS,
			message: 'Location updated successfully',
		});
		locationActions.updateSelectedLocation(location);
		setCookie();

		const path = router.asPath as string;
		router.push(path);
		locationActions.triggerCitySelector(
			LOCATION_SELECTOR_TRIGGER_OPTIONS.LOCATION_SELECTOR_CLOSE
		);
	}

	async function checkPincode() {
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'Pincode Entered',
			moe_event_data: {
				pincode: pincode,
				pincode_entered: pincode,
			},
		});
		setCheckPincodeViewState(ApiState.LOADING);
		const isPincodeValid = checkIfPincodeValid(pincode.toString());

		if (isPincodeValid) {
			try {
				const pincodeDetails = await getEnteredPincodeDetails();
				if (pincodeDetails?.isServiceable) {
					saveLocationAndGetMeta({
						cityId: pincodeDetails.city.id,
						cityName: pincodeDetails.city.name,
						pincode: Number(pincode),
					});
				} else {
					setPincodeError('Pincode is not serviceable');
					dataLayerPushWithEvent('moe_event', {
						moe_event_name: 'Unserviceable Area Error',
						moe_event_data: {
							pincode: pincode,
							pincode_entered: pincode,
						},
					});
				}
			} catch (error: any) {
				setPincodeError(
					error?.response?.data?.error?.message ?? 'Something Went Wrong'
				);
				console.error(error);
			}
		} else {
			setPincodeError('Invalid Pincode Entered');
		}

		setCheckPincodeViewState(ApiState.COMPLETED);
		// loader.hide();
	}
	return (
		<>
			
			<Box sx={{ width: '100%', padding: '0 24px' }}>
			<FlexBox sx={{gap:'10px',justifyContent:'center',alignItems:'center',pb:'12px'}} onClick={()=>{setShowPincode(!showPincode);
				if(!showPincode){
					emitMoeEvent('Pincode CTA clicked')
				}
			}}>
				<Typography sx={{...getStyle('Small-Medium'),color:getColor('Minty 0'),textDecoration:'underline'}}>Enter your pincode</Typography>
				<Box sx={{transform:showPincode?'rotate(0deg)':'rotate(180deg)',transition:'transform 0.5s'}}>	
					<UpArrow/>
				</Box>
			</FlexBox>
				{showPincode && <Box
					sx={{
						...getStyle('Small-Medium'),
						position: 'relative',
						backgroundColor: getColor(''),
						height: '48px',
						width: '100%',
						border: pincodeError
							? `1px solid ${getColor('Danger +1')}`
							: `1px solid ${getColor('Gray -4')}`,
						borderRadius: '24px',
						mb:'8px'
					}}
					// ref={pinref}
				>
					<TextField
						sx={{
							paddingLeft: '24px',
							zIndex: '99',
							paddingTop: '10px',
							width: '70%',
							...getStyle('Small-Medium'),
							input: {
								'&::placeholder': {
									...getStyle('Small-Medium'),
									color: '#777777',
									opacity: '1',
								},
								color: getColor('Gray -2'),
								...getStyle('Small-Medium'),
							},
						}}
						name='mobile'
						value={pincode}
						onChange={onPincodeEnter}
						// onKeyUp={onKeyUp}
						// inputRef={inputMobileRef}
						placeholder='Enter your pincode'
						variant='standard'
						autoComplete='off'
						InputProps={{
							disableUnderline: true,
							type: 'tel',
						}}
						inputProps={{
							minLength: 6,
							maxLength: 6,
							color: getColor(''),
						}}
					/>
					<Box
						sx={{ position: 'absolute', top: '12px', right: '17px' }}
						onClick={() => checkPincode()}
					>
						<RightArrow strokeColor={'#069BAA'} />
					</Box>
				</Box>}
			</Box>
			{pincodeError && (
				<Typography
					sx={{
						...getStyle('Small-Regular'),
						color: getColor('Danger +1'),
						width: '100%',
						textAlign: 'center',
					}}
				>
					{pincodeError}
				</Typography>
			)}
			{!pincodeError && (
				<Typography
					sx={{
						...getStyle('Small-Medium'),
						color: '#888888',
						width: '100%',
						textAlign: 'center',
					}}
				>
					Currently selected pincode &#58;
					<Typography
						component='span'
						sx={{
							...getStyle('Small-SemiBold'),
							color: '#303133',
							paddingLeft: '2px',
						}}
					>
						{selectedLocation?.pincode}
					</Typography>
				</Typography>
			)}
		</>
	);
};

export default PincodeInput;
