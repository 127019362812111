import FlexBox from '@/atomic-components/flex-box';
import { LencoImage } from '@/atomic-components/image';
import { ICity } from '@/models/location';
import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import { Box, Typography } from '@mui/material';
import BengaluruIcon from '@/assets/city/bengaluru.svg';
import MumbaiIcon from '@/assets/city/mumbai.svg';
import NoidaIcon from '@/assets/city/noida.svg';
import PuneIcon from '@/assets/city/pune.svg';
import HyderabadIcon from '@/assets/city/hyderabad.svg';
import DelhiIcon from '@/assets/city/delhi.svg';
import ChennaiIcon from '@/assets/city/chennai.svg';
import GhaziabadIcon from '@/assets/city/ghaziabad.svg';
import GurugramIcon from '@/assets/city/gurugram.svg';
import FaridabadIcon from '@/assets/city/faridabad.svg';
import JaipurIcon from '@/assets/city/jaipur.svg';
import MysuruIcon from '@/assets/city/mysore.svg';
import NashikIcon from '@/assets/city/nasik.svg';
import ChandigarhIcon from '@/assets/city/chandigarh.svg';
import VijaywadaIcon from '@/assets/city/vijaywada.svg';
import LucknowIcon from '@/assets/city/lucknow.svg';
import GandhinagarIcon from '@/assets/city/gandhinagar.svg';
import AhmedabadIcon from '@/assets/city/ahmedabad.svg';
import KolkataIcon from '@/assets/city/kolkata.svg';
import IndoreIcon from '@/assets/city/indore.svg';

type TopCitiesProps = {
	cities?: ICity[];
	onCityClicked: (city: ICity) => void;
};

const CityIConMapper = new Map([
	['Bengaluru', { icon: <BengaluruIcon /> }],
	['Mumbai', { icon: <MumbaiIcon /> }],
	['Noida', { icon: <NoidaIcon /> }],
	['Pune', { icon: <PuneIcon /> }],
	['Delhi', { icon: <DelhiIcon /> }],
	['Gurugram', { icon: <GurugramIcon /> }],
	['Hyderabad', { icon: <HyderabadIcon /> }],
	['Ghaziabad', { icon: <GhaziabadIcon /> }],
	['Chennai', { icon: <ChennaiIcon /> }],
	['Faridabad', { icon: <FaridabadIcon /> }],
	['Jaipur', { icon: <JaipurIcon /> }],
	['Mysuru', { icon: <MysuruIcon /> }],
	['Nashik', { icon: <NashikIcon /> }],
	['Chandigarh', { icon: <ChandigarhIcon /> }],
	['Vijayawada', { icon: <VijaywadaIcon /> }],
	['Kolkata', { icon: <KolkataIcon /> }],
	['Ahmedabad', { icon: <AhmedabadIcon /> }],
	['Lucknow', { icon: <LucknowIcon /> }],
	['Indore', { icon: <IndoreIcon /> }],
	['Gandhinagar', { icon: <GandhinagarIcon /> }],
]);

const TopCities = (props: TopCitiesProps) => {
	return (
		<FlexBox
			sx={{
				flexWrap: 'wrap',
				marginLeft: '16px',
				marginRight: '16px',
				justifyCenter: 'center',
				marginBottom: '30px',
			}}
		>
			<FlexBox direction='column' sx={{ width: '100%', marginTop: '30px' }}>
				<Box
					sx={{
						width: '95%',
						border: `1px solid ${getColor('Minty -3')}`,
					}}
				></Box>
				<Typography
					sx={{
						...getStyle('H11-Regular'),
						padding: '0 10px',
						color: getColor('Gray 0'),
						letterSpacing: '0px',
						position: 'absolute',
						background: 'white',
					}}
				>
					Or select your city
				</Typography>
			</FlexBox>
			{props.cities?.map((city: ICity) => {
				const cityIcon = CityIConMapper.get(city.name);

				return (
						<FlexBox
							key={city.name}
							direction='column'
							alignItems='center'
							justify='center'
							onClick={() => props.onCityClicked(city)}
							sx={{ padding: '24px 0px 0px 0px', margin: '0 8px' }}
						>
							<Box
								sx={{
									width: '70px',
									height: 'auto',
								}}
							>
								{cityIcon ? (
									<Box>{cityIcon.icon}</Box>
								) : (
									// <LencoImage url={city.icon_url} aspectRatio={1} />
									<></>
								)}
							</Box>
							<Typography
								sx={{
									...getStyle('Tiny-Medium'),
									color: getColor('Gray 0'),
								}}
							>
								{city.name}
							</Typography>
						</FlexBox>
					)
			})}
		</FlexBox>
	);
};

export default TopCities;
