import FlexBox from '@/atomic-components/flex-box';
import { ICity } from '@/models/location';
import { getColor } from '@/utils/getColor';
import { getStyle } from '@/utils/getStyle';
import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
} from '@mui/material';
import DownArrow from '@/assets/city/down-arrow-city.svg';
import { useState } from 'react';

type OtherCitiesProps = {
	cities: ICity[];
	onCityClicked: (city: ICity) => void;
};

const OtherCities = (props: OtherCitiesProps) => {
	const [otherCitiesListVisible, setOtherCitiesListVisible] =
		useState<boolean>(true);

	const displayOtherCitiesList = () => {
		setOtherCitiesListVisible(true);
	};

	const closeOtherCitiesList = () => {
		setOtherCitiesListVisible(true);
	};

	return (
		<>
			{/* {props?.cities?.length > 0 && (
				<Typography
					sx={{
						...getStyle('Small-Medium'),
						marginTop: '40px',
						width: '100%',
						color: getColor('Gray 0'),
						textAlign: 'center',
					}}
					onClick={displayOtherCitiesList}
				>
					Other cities <DownArrow />
				</Typography>
			)} */}
			<Accordion
				defaultExpanded={otherCitiesListVisible}
				expanded={otherCitiesListVisible}
				onClick={()=>{}}
				sx={{
					boxShadow: 'none',
					marginTop: '0',
					width: '100%',
					'::before': {
						display: 'none',
					},
				}}
				style={{ margin: 0 }}
			>
				<AccordionSummary
					style={{ margin: ' 0 !important' }}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					{otherCitiesListVisible && (
						<Typography
							sx={{
								...getStyle('H11-Medium'),
								color: getColor('Gray 0'),
								// marginTop: '20px',
							}}
							onClick={closeOtherCitiesList}
						>
							Other Cities
						</Typography>
					)}
				</AccordionSummary>
				<AccordionDetails sx={{ width: '100%', paddingTop: '0' }}>
					<FlexBox direction='column' align='start' sx={{ width: '100%' }}>
						{props?.cities?.length > 0 &&
							props.cities?.map((city: ICity) => {
								return (
									<>
										<Typography
											sx={{
												...getStyle('Small-Medium'),
												color: '#000000',
											}}
											onClick={() => {
												props.onCityClicked(city);
											}}
											key={city.name}
										>
											{city.name}
										</Typography>
										<Box
											sx={{
												width: '100%',
												marginRight: '24px',
												border: `1px solid ${getColor('Gray -5')}`,
												marginTop: '8px',
												marginBottom: '8px',
											}}
										></Box>
									</>
								);
							})}
					</FlexBox>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

export default OtherCities;
